import React from "react";
import { Puff } from "react-loader-spinner";

const AppLoader = ({ fullScreen }: { fullScreen?: boolean }) => {
  return (
    <div
      style={{
        height: fullScreen ? "100vh" : "100%",
        width: fullScreen ? "100vw" : "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "80px",
      }}
    >
      <Puff
        visible={true}
        height='100'
        width='180'
        color='#f58a44'
        ariaLabel='puff-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </div>
  );
};

export default AppLoader;
