import { createBrowserRouter } from "react-router-dom";

import { Fragment, Suspense, lazy } from "react";

import AppLoader from "../shared/AppLoader";

import ProtectedRoute from "../components/AuthGuard";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Login = lazy(() => import("../pages/Login"));
const Users = lazy(() => import("../pages/Users"));
const Trade = lazy(() => import("../pages/Trade"));
const Tickets = lazy(() => import("../pages/Tickets"));
const Settings = lazy(() => import("../pages/Settings"));
const Notifications = lazy(() => import("../pages/Notifications"));
const MobileTicket = lazy(() => import("../pages/MobileTicket"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const EnterOtp = lazy(() => import("../pages/EnterOtp"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));

interface IRoutes {
  path: string;
  element: JSX.Element;
  authGuard: boolean;
}

const routes: IRoutes[] = [
  {
    path: "/",
    element: <Dashboard />,
    authGuard: true,
  },
  {
    path: "/login",
    element: <Login />,
    authGuard: false,
  },
  {
    path: "/users",
    element: <Users />,
    authGuard: true,
  },
  {
    path: "/trade",
    element: <Trade />,
    authGuard: true,
  },
  {
    path: "/tickets",
    element: <Tickets />,
    authGuard: true,
  },
  {
    path: "/settings",
    element: <Settings />,
    authGuard: true,
  },
  { path: "/notifications", element: <Notifications />, authGuard: true },
  { path: "/new-password", element: <ResetPassword />, authGuard: false },
  { path: "/enter-otp", element: <EnterOtp />, authGuard: false },
  { path: "/forgot-password", element: <ForgotPassword />, authGuard: false },
  {
    path: "mobile-ticket/:jwt/:ticketId",
    element: <MobileTicket />,
    authGuard: false,
  },
];

const router = createBrowserRouter(
  routes.map(({ path, element, authGuard }) => {
    const Component: any = authGuard ? ProtectedRoute : Fragment;
    return {
      path,
      element: (
        <Suspense fallback={<AppLoader fullScreen />}>
          <Component>{element}</Component>
        </Suspense>
      ),
    };
  })
);

export default router;
