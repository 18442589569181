import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { proApi } from "./http/proApi";

export const store = configureStore({
  reducer: {
    [proApi.reducerPath]: proApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(proApi.middleware),
});

// setupListeners(store.dispatch);
