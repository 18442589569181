import { Theme, createTheme } from "@mui/material";

const theme: Theme = createTheme({
  typography: {
    fontFamily: "Mona Sans",
  },
  palette: {
    primary: { main: "#F47422", light: "#fef2e9" },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: "12px",
          textTransform: "inherit",
        },
        contained: {
          color: "#fff",
          textTransform: "inherit",
        },
        containedInfo: {
          backgroundColor: "#FFF9F0",
          color: "#FF8100",
          fontSize: "14px",
          fontWeight: 500,
          borderRadius: "8px",
          padding: "6px 12px",
          "&:hover": {
            backgroundColor: "#FFF9F0",
            color: "#FF8100",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontWeight: 500,
          fontSize: "14px",
          textTransform: "inherit",
        },
        // Variant = filled
        filled: {
          background: "#F3FCF5",
          color: "#009C1F",
        },
        //Color => error
        colorError: {
          color: "#E70017",
          background: "#fef2f3",
        },
        //Color => warning
        colorWarning: {
          color: "#FF8100",
          background: "#FFF9F0",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          outline: "none",
        },
      },
    },
  },
});

export default theme;
